import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "@layout";
import Table from "react-bootstrap/Table";
import SEO from "@components/seo";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import Profile from "../../components/Profile";
import { normalizedData } from "@utils/functions";
import jsonDataTitleInit from "../../data/about/re-insurersTitle/jsonData.json";
import jsonDataTableInit from "../../data/about/re-insurersTable/jsonData.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const ReInsurers = ({ data, location, pageContext }) => {
    const [jsonDataTitle, setJsonDataTitle] = useState(jsonDataTitleInit);
    const [jsonDataTable, setJsonDataTable] = useState(jsonDataTableInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a6fb3c6ac35807d3a2c2c6"
        )
            .then((resultData) => {
                setJsonDataTitle(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a6fcde6ac35807d3a2c2fb"
        )
            .then((resultData) => {
                setJsonDataTable(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="Re-insurers" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Re-insurers"
            />
            <Container style={{ lineHeight: "130%" }}>
                <h3>{jsonDataTitle.title}</h3>
                <p style={{ color: "#000" }}>{jsonDataTitle.title1}</p>
                <p style={{ color: "#000" }}>{jsonDataTitle.title2}</p>
                <ul>
                    <li style={{ color: "#000" }}>
                        <b style={{ color: "#470880" }}>{jsonDataTitle.text}</b>{" "}
                        {jsonDataTitle.text1}
                    </li>
                    <li style={{ color: "#000" }}>
                        <b style={{ color: "#470880" }}>
                            {jsonDataTitle.text2}
                        </b>{" "}
                        {jsonDataTitle.button}
                    </li>
                </ul>

                <Table striped bordered hover>
                    <thead>
                        <tr style={{ backgroundColor: "#470880" }}>
                            <th
                                colSpan={4}
                                style={{
                                    color: "#fff",
                                    textAlign: "center",
                                }}
                            >
                                {jsonDataTable[0].tdData[0]}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {jsonDataTable
                            .filter((tr, i) => i > 0)
                            .map((tr, j) => {
                                return (
                                    <tr key={j}>
                                        <td
                                            colSpan={2}
                                            style={{
                                                color: "#470880",
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                                fontWeight: "bolder",
                                            }}
                                        >
                                            {tr.tdData[0]}
                                        </td>
                                        <td colSpan={2}>
                                            {tr.tdData[1].map((p, jj) => {
                                                return (
                                                    <p
                                                        key={jj}
                                                        style={{
                                                            color: "#000",
                                                        }}
                                                    >
                                                        {p}
                                                    </p>
                                                );
                                            })}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </Container>
        </Layout>
    );
};
ReInsurers.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query ReInsurersQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default ReInsurers;
